import React from 'react'
import { Container } from 'react-bootstrap'
import Layout from "../components/Layout"
import { useEffect } from 'react'
import AOS from 'aos';
import Head from '../components/Head';


function Infrastructure() {
    useEffect(() => {
        AOS.init({
          duration : 1000
        });
        AOS.refresh();
    
      }, []);

  return (
        <div className='overflow-hidden '>
            <Head title="Infrastructure"/>
            <Layout>
                <Container className="px-5" style={{marginTop:'6rem'}}>
                    <header className="sectionHeader halfBorder mb-5 headerLeftMargin">Infrastructure</header>

                    <div className="gallery d-flex flex-wrap"> 
                        <figure data-aos="fade-up-right">
                            <img fluid  src="/campus/infrastructure/IMG-20180519-WA0009.jpg" alt="" />
                        </figure>   
                        <figure data-aos="fade-up-left">
                            <img fluid  src="/campus/infrastructure/IMG-20180519-WA0007.jpg" alt="" />
                        </figure> 
                        <figure data-aos="fade-up-right">
                            <img fluid  src="/campus/infrastructure/IMG-20180519-WA0010.jpg" alt="" />
                        </figure>   
                        <figure data-aos="fade-up-left">
                            <img fluid  src="/campus/infrastructure/IMG_20211119_130329_594.jpg" alt="" />
                        </figure>
                        <figure data-aos="fade-up-right">
                            <img fluid  src="/campus/infrastructure/IMG_20210819_182106_410.jpg" alt="" />
                        </figure>   
                        {/* <figure data-aos="fade-up-left">
                            <img fluid  src="/campus/infrastructure/COLLEGE CAMPUS (2).jpg" alt="" />
                        </figure>   */}
                        <figure data-aos="fade-up-left">
                            <img fluid  src="/campus/infrastructure/IMG-20180519-WA0006.jpg" alt="" />
                        </figure> 
                        <figure data-aos="fade-up-left">
                            <img fluid  src="/campus/infrastructure/IMG-20180519-WA0008.jpg" alt="" />
                        </figure> 
                        <figure data-aos="fade-up-left">
                            <img fluid  src="/campus/infrastructure/IMG-20180519-WA0011.jpg" alt="" />
                        </figure> 
                        <figure data-aos="fade-up-left">
                            <img fluid  src="/campus/infrastructure/resized-image-Promo.jpeg" alt="" />
                        </figure> 
                    </div>
                    

                </Container>
            </Layout>
            
        </div>
  )
}

export default Infrastructure